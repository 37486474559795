import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { TWhisper, WhisperSchema } from "../../types"
import { PostWhisper } from "../../services/request/Whisper"
import { GetAccountByNickname } from "../../services/request/Account"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "sonner"
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"
import { Button } from "@/components/ui/button"

type Props = {
  nickName: string
  onCancel: () => void
}

export default function Whisper({ nickName, onCancel }: Props) {
  const { t } = useTranslation()
  const [sendError, setSendError] = useState(false)

  const form = useForm<TWhisper>({
    resolver: zodResolver(WhisperSchema),
  })

  const { data } = useQuery({
    queryKey: ["inviteUser" + nickName],
    queryFn: () => GetAccountByNickname(nickName),
    enabled: !!nickName,
  })

  const handleSend = async (whisper: TWhisper) => {
    try {
      await PostWhisper({
        message: whisper.message,
        client_account_id: data?.id!,
      })
      form.reset()
      toast.success(t("Whisper.Sent"))
    } catch (error: any) {
      setSendError(true)
    }
  }

  if (sendError) {
    return (
      <div className="flex w-full flex-col gap-2">
        <p className="text-red-400">Error</p>
      </div>
    )
  }

  return (
    <Form {...form}>
      <form
        className="flex w-full flex-col gap-2"
        onSubmit={form.handleSubmit(handleSend)}
      >
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-balance text-foreground">
                {t("Whisper.Message")}
              </FormLabel>
              <Textarea
                {...field}
                placeholder={t("Whisper.PlaceHolder")}
                rows={4}
                maxLength={200}
              />
              <FormMessage className="text-red-400" />
            </FormItem>
          )}
        />
        <div className="flex justify-end gap-2">
          <Button variant="secondary" onClick={onCancel}>
            {t("Whisper.Cancel")}
          </Button>
          <Button disabled={form.formState.isSubmitting} type="submit">
            {t("Whisper.Send")}
          </Button>
        </div>
      </form>
    </Form>
  )
}
