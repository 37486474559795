import { ReactNode } from "react"
import { HeaderView } from "./Header"
import { Footer } from "../footers/Footer"
import { Toaster } from "@/components/ui/sonner"

type Props = {
  children: ReactNode
}

export const LayoutViewSecondary = ({ children }: Props) => {
  return (
    <div className="flex min-h-screen flex-col">
      <HeaderView />
      <div className="flex-grow p-2 md:container md:p-0">{children}</div>
      <Toaster position="top-center" />
      <Footer />
    </div>
  )
}
