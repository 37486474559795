import axios from "axios"
import { env } from "../../utils/env"

const AXIOS_SETUP = {
  baseURL: env.DUO_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
}

export const publicApi = axios.create(AXIOS_SETUP)

export const privateApi = axios.create({
  ...AXIOS_SETUP,
  baseURL: env.DUO_API_URL,
  headers: {
    ...AXIOS_SETUP.headers,
    Authorization: `Bearer ${localStorage.getItem("@DuoToPlay:token")}`,
  },
})

privateApi.interceptors.response.use(
  (res) => res,
  (err) => {
    console.error(err.response)
    if (err.response.status === 401) {
      localStorage.removeItem("@DuoToPlay:userId")
      localStorage.removeItem("@DuoToPlay:token")
      localStorage.removeItem("@DuoToPlay:name")
      localStorage.removeItem("@DuoToPlay:avatar")
      window.location.href = "/login"
    }
    return Promise.reject(err)
  },
)
