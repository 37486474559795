import { Currencies } from "../constants/currencies"
import i18n from "../i18n"

export const convertCurrency = (amount?: number): number => {
  if (!amount) return +(0).toFixed(2)
  return +(amount * 0.01).toFixed(2)
}

export const convertToBaseCurrency = (amount?: number): number => {
  if (!amount) return +(0).toFixed(2)
  return +(amount * 100)
}

export const symbolCurrency = (value?: string): string => {
  const Currency = Currencies.find((c) => c.value === value)
  return Currency?.symbol || "R$"
}

type FormatCurrencyProps = {
  lang?: string
  currency?: string
  value: number
}
export function FormatCurrency({
  value,
  currency = "BRL",
  lang = i18n.language,
}: FormatCurrencyProps) {
  return Intl.NumberFormat(lang, {
    style: "currency",
    currency,
  }).format(value * 0.01)
}
