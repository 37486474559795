import { useTranslation } from "react-i18next"
import { Button } from "../ui/button"
import { Link } from "react-router-dom"
import { getCreateDuoPath } from "@/lib/getRoutePath"
import { Plus } from "lucide-react"

export function CreateMatchCard() {
  const { t } = useTranslation()

  return (
    <Button
      asChild
      className="h-[22rem] w-[15rem] items-center justify-center transition-transform hover:scale-105 hover:opacity-100"
      variant="outline"
    >
      <Link to={getCreateDuoPath()}>
        <Plus />
        {t("Duo.create")}
      </Link>
    </Button>
  )
}
