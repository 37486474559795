import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { t } from "i18next"
import { Markdown } from "@/components/Markdown"
import { Spinner } from "@/components/Spinner"

export default function Terms() {
  const { i18n } = useTranslation()
  const location = useLocation()

  const { data, isLoading } = useQuery({
    queryKey: ["terms", i18n.language],
    queryFn: async () => {
      const { data } = await axios.get<string>(
        `${process.env.PUBLIC_URL}/terms-${i18n.language}.md`,
      )
      return data
    },
    enabled: !!i18n.language,
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.terms.title")}</title>
        <meta name="description" content={t("Helmet.terms.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="container">
        {isLoading ? (
          <div className="flex flex-grow items-center justify-center">
            <Spinner className="size-10 text-secondary" />
          </div>
        ) : (
          <Markdown text={data} />
        )}
      </div>
    </>
  )
}
