export function YoutubeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="#ffffff"
      height={25}
      width={24}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
    >
      <g>
        <g>
          <g>
            <path
              d="M380,70H110C49.346,70,0,119.346,0,180v130c0,60.654,49.346,110,110,110h270c60.654,0,110-49.346,110-110V180
				C490,119.346,440.654,70,380,70z M470,310c0,49.626-40.374,90-90,90H110c-49.626,0-90-40.374-90-90V180c0-49.626,40.374-90,90-90
				h270c49.626,0,90,40.374,90,90V310z"
            />
            <path
              d="M323.846,235.769l-120-50c-3.085-1.286-6.611-0.945-9.393,0.911c-2.782,1.854-4.453,4.977-4.453,8.32v100
				c0,3.344,1.671,6.466,4.453,8.32c1.667,1.112,3.601,1.68,5.548,1.68c1.301,0,2.608-0.254,3.845-0.769l120-50
				c3.727-1.553,6.154-5.194,6.154-9.231C330,240.963,327.572,237.322,323.846,235.769z M210,280v-70l84,35L210,280z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
