import { createContext, useContext, useState } from "react"

interface UserContextData {
  online: boolean
  setIsOnline: (online: boolean) => void
}

export const IsUserOnlineOnTwitchContext = createContext<UserContextData>(
  {} as UserContextData,
)
export const IsUserOnlineOnTwitchProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [online, setIsOnline] = useState<boolean>(false)

  return (
    <IsUserOnlineOnTwitchContext.Provider value={{ online, setIsOnline }}>
      {children}
    </IsUserOnlineOnTwitchContext.Provider>
  )
}

export const useIsUserOnlineOnTwhtichContext = () =>
  useContext(IsUserOnlineOnTwitchContext)
