import { useQuery } from "@tanstack/react-query"
import { GetStripeConfig } from "../services/request/Config"
import { loadStripe } from "@stripe/stripe-js"

export function useStripePromise() {
  return useQuery({
    queryKey: ["ConfigStripe"],
    queryFn: async () => {
      const { key } = await GetStripeConfig()
      return loadStripe(key)
    },
  })
}
