import { TwitchIcon } from "@/components/Icons"
import { AnimatePresence, motion } from "motion/react"
interface NotificationProps {
  buyer?: string
  streamer?: string
  product?: string
  game?: string
  duration?: number
  isVisible?: boolean
}

export function ObsMessage({
  buyer = "FULANO",
  streamer = "STREAMER",
  product = "DUOTOPLAY",
  game = "GAME",
  isVisible = false,
}: NotificationProps) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="mx-auto w-full p-4"
        >
          <div className="relative overflow-hidden rounded-lg bg-gradient-to-r from-zinc-950 to-zinc-900 shadow-2xl">
            <div className="absolute inset-0 bg-transparent backdrop-blur" />
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: "100%" }}
              transition={{ repeat: Infinity, duration: 5000, ease: "linear" }}
              className="absolute inset-y-0 w-1/3 bg-gradient-to-r from-transparent via-zinc-800/10 to-transparent"
            />

            <div className="relative px-6 py-4">
              <p className="text-center text-xl font-bold uppercase tracking-tight text-zinc-100 sm:text-2xl">
                <span className="text-blue-500">{buyer}</span> COMPROU UMA{" "}
                {product} DE <span className="text-orange-500">{game}</span> COM{" "}
                <span className="inline-flex items-center gap-1 text-purple-400">
                  {streamer}
                  <TwitchIcon className="h-5 w-5 text-purple-400" />
                </span>
              </p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
