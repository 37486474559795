import { Button } from "@/components/ui/button"
import { Pencil } from "lucide-react"
import { HTMLAttributes } from "react"
import { Link } from "react-router-dom"

type EditButtonProps = Omit<HTMLAttributes<HTMLButtonElement>, "children"> & {
  to?: string
}
export function EditButton(props: EditButtonProps) {
  if (props.to) {
    return (
      <Button className="size-6 p-0 [&_svg]:size-3" asChild>
        <Link to={props.to}>
          <Pencil size={1} />
        </Link>
      </Button>
    )
  }
  return (
    <Button className="size-6 p-0 [&_svg]:size-3" {...props}>
      <Pencil size={1} />
    </Button>
  )
}
