import { useTranslation } from "react-i18next"
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"
import { getAllStreamersPath } from "@/lib/getRoutePath"

export function PaymentError() {
  const { t } = useTranslation()

  return (
    <Card className="w-full max-w-md bg-card">
      <CardHeader className="text-center">
        <CardTitle className="text-2xl font-bold">
          {t("Payment.Error.title")}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-center text-foreground">
          {t("Payment.Error.description")}
        </p>
      </CardContent>
      <CardFooter>
        <Button asChild className="w-full" variant="secondary">
          <Link to={getAllStreamersPath()}>{t("Payment.Error.backToStreamers")}</Link>
        </Button>
      </CardFooter>
    </Card>
  )
}
