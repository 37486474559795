export function RightSquareOutlined(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.822 21.173l6.231-4.897a.352.352 0 000-.553l-6.231-4.9a.353.353 0 00-.572.275v9.797c0 .294.34.46.572.278z"
        fill="#8C8C8C"
      />
      <path
        d="M27.5 3.5h-23c-.553 0-1 .447-1 1v23c0 .553.447 1 1 1h23c.553 0 1-.447 1-1v-23c0-.553-.447-1-1-1zm-1.25 22.75H5.75V5.75h20.5v20.5z"
        fill="#DCDCDC"
      />
    </svg>
  )
}
