import { Navigate, useLocation } from "react-router-dom"
import { useAuthContext } from "../context/auth"
import { getLoginPath } from "@/lib/getRoutePath"

type PrivateRouteProps = {
  Component: JSX.Element
}

export function PrivateRoute({ Component }: PrivateRouteProps) {
  const { isLoggedIn } = useAuthContext()
  const location = useLocation()
  const from = location.pathname + location.search

  if (!isLoggedIn) {
    localStorage.setItem("@DuoToPlay:history", from)

    return <Navigate to={getLoginPath()} />
  }

  return Component
}
