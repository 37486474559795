import { TTalent } from "../../../pages/talents"
import { TAccount, TAccountPerformance } from "../../../types"
import { privateApi, publicApi } from "../../api"

export async function GetAccountByNickname(
  nickname: string,
): Promise<TAccount> {
  const res = await publicApi.get<TAccount>(`/${nickname}/profile`)
  return res.data
}
export async function GetAccountPerformance(): Promise<TAccountPerformance> {
  const res = await privateApi.get<TAccountPerformance>(`/performance`)
  return res.data
}

export async function GetLinkToConnectStripe() {
  const res = await privateApi.get<{ link_to_connect: string }>(
    "/account/stripe-connect-link",
  )
  return res.data
}

export async function GetAllAccounts () {
  const res = await privateApi.get<TTalent[]>("/account/all", {
    params: {
      page: 1,
      per_page: 10000,
      isTalent: true,
    },
  })
  return res.data
}
