import { PollyClient } from "@aws-sdk/client-polly" // ES Modules import
import { env } from "../../utils/env"

export const polly = new PollyClient({
  credentials: {
    accessKeyId: env.AWS_ACCESS_KEY_ID,
    secretAccessKey: env.AWS_SECRET_ACCESS_KEY,
  },
  region: env.AWS_REGION,
})
