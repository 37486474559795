import { Skeleton } from "@/components/ui/skeleton"
import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

import { useQuery } from "@tanstack/react-query"
import { GetAccountPerformance } from "../../../services/request/Account"
import { FormatCurrency } from "../../../utils/currencyHelper"
import { useUser } from "../../../hook/useUser"
import { ChartLine, CircleDollarSign, Eye, EyeOff, PlaySquare } from "lucide-react"
import { InfoTooltip } from "@/components/InfoTooltip"

export function StreamerPerformance() {
  const { t } = useTranslation()
  const { data: user } = useUser()
  const [showBalance, setShowBalance] = useState(false)

  const { data: perform } = useQuery({
    queryKey: ["getAccountPerformance"],
    queryFn: () => GetAccountPerformance(),
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!user?.isTalent,
  })

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="space-x-2 flex">
          <ChartLine className="text-slate-600" />
          <span>{t("PerformanceDay.title")}</span>
          <InfoTooltip className="size-4">
            {t("PerformanceDay.tooltip")}
          </InfoTooltip>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex gap-6 pt-2">
        <Metric
          title={t("Balance")}
          icon={<div className="bg-emerald-500/30 text-emerald-300 rounded-md flex items-center justify-center px-2.5 h-[65px] w-[65px] opacity-70">
            <CircleDollarSign size="35" />
          </div>
          }
        >
          {showBalance ? (
            <div className="flex items-center text-2xl font-bold h-[40px]">
              {FormatCurrency({
                value: perform?.amount || 0,
                currency: perform?.currency || "BRL",
              })}
            </div>
          ) : (
            <Skeleton className="h-[40px] w-[90px] animate-none" />
          )}

          <Button
            variant="ghost"
            size="icon"
            onClick={() => setShowBalance(!showBalance)}
          >
            {showBalance ? <EyeOff /> : <Eye />}
          </Button>
        </Metric>

        <Metric
          title={t("Resume.matchesPerformed")}
          icon={<div className="bg-sky-500/30 text-sky-300 rounded-md flex items-center justify-center px-2.5 h-[65px] w-[65px] opacity-70">
            <PlaySquare size="35" />
          </div>
          }
        >
          <div className="text-2xl font-bold">
            {perform?.matches_played || 0}
          </div>
        </Metric>
      </CardContent>
    </Card>
  )
}

function Metric({ title, icon, children }: { title: ReactNode; icon: ReactNode; children: ReactNode }) {
  return (
    <div className="w-1/2 bg-zinc-900 rounded-md p-4 flex items-center gap-3">
      {icon}
      <div className="space-y-1">
        <h4 className="text-sm font-medium">
          {title}
        </h4>
        <div className="flex gap-2">
          {children}
        </div>
      </div>
    </div>
  )
}