export function InstagramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 7.194A4.8 4.8 0 007.691 12a4.8 4.8 0 004.807 4.807A4.8 4.8 0 0017.306 12 4.8 4.8 0 0012.5 7.194zm0 7.93a3.13 3.13 0 01-3.125-3.123 3.13 3.13 0 013.124-3.125 3.13 3.13 0 013.124 3.125 3.13 3.13 0 01-3.124 3.124zm5.003-9.248c-.621 0-1.123.502-1.123 1.123s.502 1.123 1.123 1.123a1.12 1.12 0 001.038-1.553 1.123 1.123 0 00-1.038-.693zm4.366 6.125c0-1.294.012-2.576-.06-3.867-.073-1.5-.415-2.832-1.512-3.929-1.1-1.099-2.428-1.439-3.928-1.511-1.294-.073-2.576-.061-3.867-.061-1.294 0-2.576-.012-3.868.06-1.5.073-2.83.415-3.928 1.512-1.099 1.1-1.439 2.428-1.511 3.928-.073 1.294-.061 2.576-.061 3.868 0 1.291-.012 2.575.06 3.867.073 1.5.415 2.831 1.512 3.928 1.1 1.1 2.428 1.44 3.928 1.512 1.294.072 2.576.06 3.867.06 1.294 0 2.576.012 3.868-.06 1.5-.073 2.831-.415 3.928-1.512 1.1-1.1 1.439-2.428 1.512-3.928.075-1.292.06-2.574.06-3.867zm-2.062 5.526a2.978 2.978 0 01-.708 1.074c-.33.33-.647.536-1.073.707-1.233.49-4.16.38-5.527.38-1.366 0-4.296.11-5.529-.377a2.978 2.978 0 01-1.073-.708 2.997 2.997 0 01-.708-1.073c-.487-1.236-.377-4.163-.377-5.53 0-1.366-.11-4.295.377-5.528.171-.427.377-.746.708-1.074a3.03 3.03 0 011.073-.707c1.233-.488 4.163-.378 5.53-.378 1.366 0 4.295-.11 5.528.378.427.17.745.377 1.074.707.33.33.536.647.707 1.074.488 1.233.378 4.162.378 5.529 0 1.366.11 4.293-.38 5.526z"
        fill="#DCDCDC"
      />
    </svg>
  )
}
