import { useTranslation } from "react-i18next"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogDescription,
} from "@/components/ui/alert-dialog"

type DuoClientInviteModalProps = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
}

export const MatchUninvitedModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
}: DuoClientInviteModalProps) => {
  const { t } = useTranslation()

  return (
    <AlertDialog
      open={isModalOpen}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          handleCancel()
        }
      }}
    >
      <AlertDialogPortal>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("Matches.Modal.Uninvited.title")}
            </AlertDialogTitle>
            <AlertDialogDescription className="text-pretty">
              {t("Matches.Modal.Uninvited.description")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t("Matches.Modal.Uninvited.cancel")}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleOk}>
              {t("Matches.Modal.Uninvited.confirm")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  )
}
