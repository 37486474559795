import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { Link, useLocation, useParams } from "react-router-dom"
import { Matches } from "@/views/Duo/Matches"
import { GetProduct } from "@/services/request/Product"
import { useQuery } from "@tanstack/react-query"
import { DuoPage } from "../../views/Duo/DuoPage"
import { getEditDuoPath } from "@/lib/getRoutePath"
import { Button } from "@/components/ui/button"
import { ToggleDuo } from "@/views/Duo/ToggleDuo"

export default function Duo() {
  const location = useLocation()
  const { t } = useTranslation()
  const { duo_id } = useParams()

  const {
    data: product,
    isLoading,
  } = useQuery({
    queryKey: ["GetProduct", duo_id],
    queryFn: () => GetProduct(duo_id!),
    enabled: !!duo_id,
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.resume.title")}</title>
        <meta name="description" content={t("Helmet.resume.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>

      {product ? (
        <DuoPage
          title={t("Duo.matchesSold")}
          product={product}
          sideSlot={
            <div className="flex flex-col gap-6">
              <ToggleDuo product={product} />

              <div className="flex flex-col gap-2">
                <Button asChild variant="secondary">
                  <Link to={getEditDuoPath(duo_id)}>{t('Duo.edit')}</Link>
                </Button>
              </div>
            </div>
          }>
          <Matches />
        </DuoPage>
      ) : !isLoading && <div>Product not found</div>}
    </>
  )
}
