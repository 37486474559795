import { useTranslation } from "react-i18next"
import { TOrder } from "../../types"
import { FormatCurrency } from "../../utils/currencyHelper"
import { Link } from "react-router-dom"
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card"
import { PartyPopper } from "lucide-react"
import { Button } from "@/components/ui/button"
import { TwitchIcon } from "../TwitchIcon"
import { getStreamerProfilePath } from "@/lib/getRoutePath"

type PaymentSuccessProps = {
  order: TOrder
}

export function PaymentSuccess({ order }: PaymentSuccessProps) {
  const { t } = useTranslation()

  return (
    <div className="group relative">
      <div className="animated-background absolute -inset-0.5 rounded bg-gradient-to-r from-pink-600 to-purple-600 opacity-90 blur transition duration-500"></div>
      <Card className="relative z-10 w-full max-w-md bg-card">
        <CardHeader className="text-center">
          <div className="mb-4 flex justify-center">
            <div className="scale-100 rounded-full bg-secondary p-4 opacity-100 transition-all duration-500 ease-out">
              <PartyPopper className="size-12 text-green-400" />
            </div>
          </div>
          <CardTitle className="text-2xl font-bold">
            {t("Payment.Success.done")}
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <p className="text-center text-foreground">
            {t("Payment.Success.description", {
              Buyer: order?.client_account?.name,
              Talent: order.talent_account?.name,
            })}
          </p>
          <div className="space-y-2 rounded-lg bg-muted p-4">
            <div className="flex items-center justify-between">
              <span className="text-muted-foreground">
                {t("Payment.Success.title")}
              </span>
              <span className="font-medium">{order.item?.title}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-muted-foreground">
                {t("Payment.Success.category")}
              </span>
              <span className="font-medium">{order.item?.name}</span>
            </div>
            <div className="flex items-center justify-between border-t border-slate-400 pt-2">
              <span className="font-semibold">
                {t("Payment.Success.value")}
              </span>
              <span className="font-bold">
                {FormatCurrency({
                  value: order!.item!.value,
                  currency: order!.item!.currency,
                })}
              </span>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex flex-col space-y-2">
          <Button asChild className="w-full" variant="secondary">
            <Link to={getStreamerProfilePath(order?.talent_account?.name)}>
              {t("Payment.Success.goBackToProfile")}
            </Link>
          </Button>
          <Button asChild className="w-full">
            <Link
              to={`https://twitch.tv/${order?.talent_account?.name}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-white">
                <TwitchIcon width={24} />
              </span>
              <span>{t("Payment.Success.goToStream")}</span>
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}
