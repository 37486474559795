import { TProduct } from "@/types"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

export function ProductAmount({ product }: { product: TProduct }) {
  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        "flex w-full items-center justify-center rounded-t-sm",
        "px-2 py-1.5",
        "text-sm font-semibold",
        product.amount === 0 && "bg-red-500/10 text-red-400",
        product.amount <= 3 &&
          product.amount > 0 &&
          "bg-yellow-500/10 text-yellow-400",
        product.amount >= 4 && "bg-green-500/10 text-green-400",
      )}
    >
      {product.amount != null && t("AvailableVacancies", { count: product.amount })}
    </div>
  )
}
