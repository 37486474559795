import { useTranslation } from "react-i18next"
import { TProduct } from "../../types"
import { formatImage, PRODUCT_IMAGE_SIZE } from "@/utils/imageHelper"
import { FormatCurrency } from "@/utils/currencyHelper"
import { clsx } from "clsx"
import { useAuthContext } from "../../context/auth"
import { ProductAmount } from "./ProductAmount"
import { Button } from "../ui/button"
import { Play } from "lucide-react"
import { toast } from "sonner"
import { ProductOnline } from "./ProductOnline"
import { FaceIcon } from "@radix-ui/react-icons"
import { useNavigate } from "react-router-dom"
import { getDuoMatchesPath } from "@/lib/getRoutePath"

interface ActiveCardProps {
  product: TProduct
  execute: () => void
}

export function ProductClientCard({ product, execute }: ActiveCardProps) {
  const { t } = useTranslation()
  const { user } = useAuthContext()
  const navigation = useNavigate()

  return (
    <>
      <div
        className={
          clsx(
            "w-full max-w-[240px] rounded-sm border border-slate-800",
            !product.enable_to_sell && "opacity-50"
          )}
      >
        <ProductAmount product={product} />
        <div
          className={clsx(
            "bg-tertiary group relative flex aspect-[600/800] h-full max-h-[320px] w-full max-w-[240px] flex-shrink-0 flex-col justify-between rounded-b-sm shadow transition-all duration-200",
            product.enable_to_sell ? "cursor-pointer" : ""
          )}
          onClick={() => {
            if (user.userId === product.account_id) {
              navigation(getDuoMatchesPath(product.id))
              return
            } else if (!product.online) {
              return toast.warning(t("Product.Offline"), {
                description: t("Product.OfflineDescription"),
              })
            } else if (product.amount <= 0) {
              return toast.error(t("Product.OutOfStock"))
            }
            execute()
          }}
        >
          <div className="absolute w-full">
            {!product.avatar ? (
              <FaceIcon className="mx-auto mt-12 h-16 w-16" />
            ) : (
              <img
                height={PRODUCT_IMAGE_SIZE.height}
                width={PRODUCT_IMAGE_SIZE.width}
                src={formatImage(product.avatar)}
                alt={product.name}
                className={clsx(
                  "animate-fade-in w-full rounded-b-sm",
                  !product.online && "grayscale",
                )}
              />
            )}
          </div>
          {product.name && <div className="top-0 z-10 px-2 py-1 text-lg font-bold">
            <span className="bg-slate-500 p-1 text-xs">{product.name}</span>
          </div>}
          <div className="flex grow" />
          <div className="relative z-10 flex min-h-[150px] w-full flex-col justify-between space-y-2 rounded-b-sm bg-slate-900/90 p-2 opacity-100 backdrop-blur-sm transition-opacity">
            {product.online && product.enable_to_sell && (
              <div className="absolute right-1 top-[-30px] flex justify-end">
                <ProductOnline />
              </div>
            )}

            <div className="flex flex-col gap-1">
              <div className="text-sm font-bold">
                {product.title} {product.device && "| " + product.device}
              </div>

              <p className="text-description break-words text-xs text-slate-400">
                {product.description}
              </p>
            </div>

            <div className="space-y-2">
              <div className="flex flex-wrap items-center justify-between gap-2 border-t border-solid border-slate-800 pt-2">
                <div className="text-xl font-semibold">
                  {FormatCurrency({
                    value: product.value,
                    currency: product.currency,
                  })}
                </div>

                <Button size="icon">
                  <Play />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
