import { useTranslation } from "react-i18next";
import { StreamerOnboardingSteps } from "../StreamerOnboardingSteps";
import { getStreamerOnboardingDuoPath } from "@/lib/getRoutePath";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { OnboardingStepDescription } from "../OnboardingStepDescription";
import { Fragment } from "react/jsx-runtime";
import { Plus, Star } from "lucide-react";

export default function StreamerOnboardingStart() {
  const { t } = useTranslation()

  return (
    <div className="md:container space-y-20">
      <StreamerOnboardingSteps currentStep={1} />

      <OnboardingStepDescription
        icon={<Star className="text-yellow-500" />}
        cta={
          <Button asChild>
            <Link to={getStreamerOnboardingDuoPath()}>
              <Plus />
              {t('streamerOnboarding.steps.duo.cta')}
            </Link>
          </Button>
        }>
        <Fragment>
          <p>{t('streamerOnboarding.steps.duo.description.first')}</p>

          <p>{t('streamerOnboarding.steps.duo.description.second')}</p>
        </Fragment>
      </OnboardingStepDescription>
    </div>
  );
}