import { ReactNode } from "react"
import { Spinner } from "../Spinner"

type ErrorScreenProps = {
  isLoading: boolean
  children: ReactNode
}

export function ErrorScreen({ children, isLoading }: ErrorScreenProps) {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-black text-6xl uppercase text-white">
      {isLoading ? <Spinner className="size-10" /> : children}
    </div>
  )
}
