import { useTranslation } from "react-i18next"
import { TProduct } from "../../types"
import { formatImage, PRODUCT_IMAGE_SIZE } from "../../utils/imageHelper"
import { useState } from "react"
import { FormatCurrency } from "../../utils/currencyHelper"
import { clsx } from "clsx"
import { useMutation } from "@tanstack/react-query"
import { PutProductEnableToSell } from "../../services/request/Product"
import { queryClient } from "../../lib/reactQuery"
import { Switch } from "@/components/ui/switch"
import { EditButton } from "../EditButton"
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { ProductAmount } from "./ProductAmount"
import { ProductOnline } from "./ProductOnline"
import { getEditDuoPath } from "@/lib/getRoutePath"

interface ActiveCardProps {
  product: TProduct
  onClick: () => void
  existsSellableProduct?: boolean
}

export function ProductCard({
  product,
  onClick,
  existsSellableProduct,
}: ActiveCardProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: PutProductEnableToSell,
    onSuccess: () => {
      const cached = queryClient.getQueryData<TProduct[]>(["getProducts"])
      queryClient.setQueryData<TProduct[]>(
        ["getProducts"],
        cached ? toggleEnableToSell(cached, product) : [],
      )
    },
  })

  const toggleEnableToSell = (
    products: TProduct[],
    targetProduct: TProduct,
  ): TProduct[] => {
    return products.map((product) => {
      if (product.id === targetProduct.id) {
        return {
          ...product,
          enable_to_sell: !product.enable_to_sell,
        }
      } else if (product.external_id === targetProduct.external_id) {
        return {
          ...product,
          enable_to_sell: false,
        }
      }
      return product
    })
  }

  const handleEnableProduct = () => {
    mutateAsync({ id: product.id!, enable_to_sell: !product.enable_to_sell })
  }

  const handleClickEnableProduct = () => {
    if (!existsSellableProduct || product.enable_to_sell) {
      handleEnableProduct()
    } else {
      setOpen(true)
    }
  }

  return (
    <div className="w-full max-w-[240px] rounded-sm border border-slate-800">
      <ProductAmount product={product} />
      <div className="bg-tertiary group relative flex aspect-[600/800] h-full max-h-[320px] w-full max-w-[240px] flex-shrink-0 cursor-pointer flex-col justify-between rounded-sm shadow transition-all duration-200">
        <img
          height={PRODUCT_IMAGE_SIZE.height}
          width={PRODUCT_IMAGE_SIZE.width}
          src={formatImage(product.avatar)}
          alt={product.name}
          className={clsx(
            "animate-fade-in absolute w-full rounded-b-sm",
            !product.online && "grayscale",
          )}
          onClick={onClick}
        />
        <div className="top-0 z-10 px-2 py-1 text-lg font-bold">
          <span className="bg-slate-500 p-1 text-xs">{product.name}</span>
        </div>
        <div className="flex grow" />
        <div
          className="flex min-h-[150px] w-full flex-col justify-between space-y-2 rounded-b-sm bg-slate-900/90 p-2 opacity-100 backdrop-blur-sm transition-opacity"
          onClick={onClick}
        >
          {product.online && (
            <div className="absolute right-1 top-[-30px] flex justify-end">
              <ProductOnline />
            </div>
          )}

          <div className="flex flex-col gap-1">
            <div className="text-sm font-bold">
              {product.title} {product.device && "| " + product.device}
            </div>

            <p className="text-description break-words text-xs text-slate-400">
              {product.description}
            </p>
          </div>
          <div className="space-y-2">
            <div className="items-around flex flex-col justify-between gap-2">
              <div
                className="flex items-center justify-end gap-2"
                onClick={(event) => event.stopPropagation()}
              >
                <div
                  className="cursor-pointer"
                  onClick={(event) => {
                    event.preventDefault()
                    handleClickEnableProduct()
                  }}
                >
                  {t("Product.EnableToSell")}
                </div>

                <Switch
                  checked={product.enable_to_sell}
                  disabled={isPending}
                  onClick={(event) => {
                    event.preventDefault()
                    handleClickEnableProduct()
                  }}
                />
              </div>
            </div>

            <div className="flex items-center justify-between border-t border-solid border-slate-800 pt-2">
              <div className="text-xl font-semibold">
                {FormatCurrency({
                  value: product.value,
                  currency: product.currency,
                })}
              </div>
              <div onClick={(event) => event.stopPropagation()}>
                <EditButton to={getEditDuoPath(product.id)} />
              </div>
            </div>
          </div>
        </div>

        <AlertDialog open={open} onOpenChange={setOpen}>
          <AlertDialogPortal>
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {t("Product.Confirmation.Title")}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {t("Product.Confirmation.Description")}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>
                  {t("Product.Confirmation.Cancel")}
                </AlertDialogCancel>
                <AlertDialogAction onClick={() => handleEnableProduct()}>
                  {t("Product.Confirmation.Ok")}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
    </div>
  )
}
