import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"

import { useForm } from "react-hook-form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Slider } from "@/components/ui/slider"
import { ChevronRight, Users } from "lucide-react"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { TCategory, TCreateProduct } from "@/types"
import { useTranslation } from "react-i18next"
import { PostProduct } from "@/services/request/Product"
import MoneyInput from "@/components/money-input"
import { toast } from "sonner"
import { useNavigate } from "react-router-dom"
import { getOverviewPath } from "@/lib/getRoutePath"
import { queryClient } from "@/lib/reactQuery"

export function CreateDuoForm({ form, categories, pageAfterCreation = getOverviewPath() }: {
  form: ReturnType<typeof useForm<TCreateProduct>>;
  categories: TCategory[] | undefined
  pageAfterCreation?: string
}) {
  const { t } = useTranslation()
  const navigate = useNavigate();

  async function handleSubmit(values: TCreateProduct) {
    try {
      await PostProduct({
        ...values,
      })
      toast(t("createMatch.created"))
      queryClient.invalidateQueries({ queryKey: ['getProducts'] })
      navigate(pageAfterCreation)
    } catch (error) {
      toast(t("createMatch.errorToCreate"))
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.name.label")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("createMatch.name.placeholder")}
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {field.value?.length || 0}/60 {t("createMatch.characters")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.description.label")}</FormLabel>
              <FormControl>
                <Textarea
                  placeholder={t("createMatch.description.placeholder")}
                  className="min-h-[100px]"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {field.value?.length || 0}/124 {t("createMatch.characters")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="product_description_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.category.label")}</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("createMatch.category.placeholder")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {categories?.map((category) => (
                    <SelectItem key={category.id} value={category.id}>
                      {category.description}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="platform"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.platform.label")}</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("createMatch.platform.placeholder")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="PC">PC</SelectItem>
                  <SelectItem value="PS4">PS4</SelectItem>
                  <SelectItem value="PS5">PS5</SelectItem>
                  <SelectItem value="Xbox One">Xbox One</SelectItem>
                  <SelectItem value="Xbox Series S/X">
                    Xbox Series S/X
                  </SelectItem>
                  <SelectItem value="Nintendo">Nintendo</SelectItem>
                  <SelectItem value="Android">Android</SelectItem>
                  <SelectItem value="iPhone/iPad">iPhone/iPad</SelectItem>
                  <SelectItem value="other">
                    {t("createMatch.otherDevice")}
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <FormField
            control={form.control}
            name="device"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("createMatch.device.label")}</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger >
                      <SelectValue placeholder={t("createMatch.device.placeholder")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="desktop">Desktop</SelectItem>
                    <SelectItem value="laptop">Laptop</SelectItem>
                    <SelectItem value="console">Console</SelectItem>
                    <SelectItem value="mobile">Mobile</SelectItem>
                    <SelectItem value="PC">PC</SelectItem>
                    <SelectItem value="PS4">PS4</SelectItem>
                    <SelectItem value="PS5">PS5</SelectItem>
                    <SelectItem value="Xbox One">Xbox One</SelectItem>
                    <SelectItem value="Xbox Series S/X">
                      Xbox Series S/X
                    </SelectItem>
                    <SelectItem value="Nintendo">Nintendo</SelectItem>
                    <SelectItem value="Android">Android</SelectItem>
                    <SelectItem value="iPhone/iPad">iPhone/iPad</SelectItem>
                    <SelectItem value="other">
                      {t("createMatch.otherDevice")}
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="region"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("createMatch.region.label")}</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger >
                      <SelectValue placeholder={t("createMatch.region.placeholder")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="EU">{t("Region.Europe")}</SelectItem>
                    <SelectItem value="NA">
                      {t("Region.NorthAmerica")}
                    </SelectItem>
                    <SelectItem value="SU">
                      {t("Region.SouthAmerica")}
                    </SelectItem>
                    <SelectItem value="AS">{t("Region.Asia")}</SelectItem>
                    <SelectItem value="OC">
                      {t("Region.Oceania")}
                    </SelectItem>
                    <SelectItem value="AF">{t("Region.Africa")}</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("createMatch.quantity")}</FormLabel>
                <FormControl>
                  <div className="flex items-center space-x-4">
                    <Users className="text-gray-400" />
                    <Slider
                      min={1}
                      max={20}
                      step={1}
                      value={[field.value]}
                      onValueChange={(value) => field.onChange(value[0])}
                      className="white flex-1"
                    />
                    <span className="min-w-[2ch] text-right text-lg font-semibold text-blue-400">
                      {field.value}
                    </span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="value"
            render={() => (
              <MoneyInput
                placeholder="$0.00"
                label="Price (BRL)"
                name="value"
                form={form}
              />
            )}
          />
        </div>
        <Button variant="pretty" type="submit" grow>
          {t("createMatch.confirm")} <ChevronRight className="ml-2" />
        </Button>
      </form>
    </Form>
  )
}