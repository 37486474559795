import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { LiveIcon } from "./LiveIcon"

type IsOnlineProps = {
  title: ReactNode
  online: boolean
}

export function IsOnline({ title, online }: IsOnlineProps) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center justify-center gap-x-1 pl-0.5">
      {online ? (
        <>
          <LiveIcon />
          <span className="text-description">{title}</span>
        </>
      ) : (
        <>
          <div className="size-2 rounded-full bg-muted-foreground"></div>
          <span className="text-description">{t("sidebar.offline")}</span>
        </>
      )}
    </div>
  )
}
