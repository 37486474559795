import { GetCategories } from "@/services/request/Category";
import { createProductSchema, TCreateProduct } from "@/types";
import { CreateDuoForm } from "@/views/Duo/CreateDuoForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StreamerOnboardingSteps } from "../StreamerOnboardingSteps";
import { DuoPage } from "@/views/Duo/DuoPage";
import { getStreamerOnboardingStripePath } from "@/lib/getRoutePath";

export default function StreamerOnboardingDuo() {
  const { t } = useTranslation()
  const form = useForm<TCreateProduct>({
    defaultValues: {
      amount: 4,
    },
    resolver: zodResolver(createProductSchema(t)),
  })

  const { data: categories } = useQuery({
    queryKey: ["getCategories"],
    queryFn: GetCategories,
  })

  const chooseCategory = categories?.find(
    (category) => category.id === form.watch("product_description_id"),
  )

  return (
    <div className="md:container">
      <StreamerOnboardingSteps currentStep={1} />

      <DuoPage
        title={null}
        product={{
          ...form.getValues(),
          avatar: chooseCategory?.avatar,
          name: chooseCategory?.description,
        }}
        sideSlot={null}
      >
        <CreateDuoForm
          form={form}
          categories={categories}
          pageAfterCreation={getStreamerOnboardingStripePath()}
        />
      </DuoPage>
    </div>
  );
}