import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { CreateMatchCard } from "@/components/Match/ButtonCreateMatch"
import { ProductCard } from "@/components/Product/ProductCard"
import CardProductSkeleton from "@/components/Skeloton"
import { useUser } from "@/hook/useUser"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { GetProductsByAccount } from "@/services/request/Product"
import { Fragment } from "react/jsx-runtime"
import { useNavigate } from "react-router-dom"
import { getDuoMatchesPath } from "@/lib/getRoutePath"
import { SquarePlay } from "lucide-react"

export function StreamerProducts() {
  const { t } = useTranslation()
  const { data: user } = useUser()
  const navigate = useNavigate()

  const { data: products, isLoading: isLoadingProducts } = useQuery({
    queryKey: ["getProducts"],
    enabled: user != null,
    queryFn: () => GetProductsByAccount(user!.id),
  })

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle>
            <div className="flex gap-2">
              <SquarePlay className="text-slate-600" />
              {t("Resume.Products.title")}
            </div>
          </CardTitle>
          <CardDescription>{t("Resume.Products.tooltip")}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap items-stretch gap-6">
            {isLoadingProducts ? (
              Array.from({ length: 4 }).map((_, index) => (
                <CardProductSkeleton key={index} />
              ))
            ) : products && products.length > 0 ? (
              <>
                {products.map((product) => (
                  <ProductCard
                    product={product}
                    key={product.id}
                    existsSellableProduct={products.some(
                      (p) =>
                        p.external_id === product.external_id &&
                        p.enable_to_sell === true,
                    )}
                    onClick={() => {
                      navigate(getDuoMatchesPath(product.id))
                    }}
                  />
                ))}
                <CreateMatchCard />
              </>
            ) : (
              <CreateMatchCard />
            )}
          </div>
        </CardContent>
      </Card>
    </Fragment>
  )
}
