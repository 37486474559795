import React, { createContext, useContext, useEffect, useState } from "react"
import { privateApi, publicApi } from "../services/api"
import { redirect } from "react-router-dom"
import { useToast } from "@/components/hooks/use-toast"

interface UserContextData {
  token: string
  signIn: (identifier: string, code: string) => void
  logout: (redirect: boolean) => void
  isLoggedIn: boolean
  user: User
}

interface User {
  name: string
  userId: string
  token: string
  avatar?: string
}

interface UserResponse {
  id: string
  name: string
  token: string
  avatar: string
  url_stream: string
  profile_description: string
}

export const AuthContext = createContext<UserContextData>({} as UserContextData)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { toast } = useToast()
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    const token = localStorage.getItem("@DuoToPlay:token")
    return !!token
  })
  const [user, setUser] = useState<User>(() => getUserFromLocalStorage())

  function getUserFromLocalStorage(): User {
    const userId = localStorage.getItem("@DuoToPlay:userId")
    const token = localStorage.getItem("@DuoToPlay:token")
    const name = localStorage.getItem("@DuoToPlay:name")
    const avatar = localStorage.getItem("@DuoToPlay:avatar")

    if (userId && token && name && avatar) {
      return { userId, token, name, avatar } as User
    }

    return {} as User
  }

  useEffect(() => {
    setUser(getUserFromLocalStorage())
  }, [])

  const signIn = async (identifier: string, code: string) => {
    try {
      logout(false)
      publicApi
        .post<UserResponse>(`/oauth/authorize/callback/${identifier}`, { code })
        .then(({ data }) => {
          localStorage.setItem("@DuoToPlay:userId", data.id)
          localStorage.setItem("@DuoToPlay:token", data.token)
          localStorage.setItem("@DuoToPlay:name", data.name)
          localStorage.setItem("@DuoToPlay:avatar", data.avatar)
          privateApi.defaults.headers["Authorization"] = `Bearer ${data.token}`
          setUser({
            userId: data.id,
            token: data.token,
            name: data.name,
            avatar: data.avatar,
          })
          setIsLoggedIn(true)
        })
    } catch (error) {
      console.error(error)
      toast({
        title: "Scheduled: Catch up",
        description: "Friday, February 10, 2023 at 5:57 PM",
      })
    }
  }

  const logout = (redirectPage = true) => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("@DuoToPlay:userId")
      localStorage.removeItem("@DuoToPlay:token")
      localStorage.removeItem("@DuoToPlay:name")
      localStorage.removeItem("@DuoToPlay:avatar")
    }
    setIsLoggedIn(false)
    setUser({} as User)
    if (redirectPage) redirect("/login")
  }

  return (
    <AuthContext.Provider
      value={{
        token: user.token,
        signIn,
        logout,
        isLoggedIn: !!isLoggedIn,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
