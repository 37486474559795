import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { useUser } from "@/hook/useUser"
import { getStreamerOnboardingStartPath } from "@/lib/getRoutePath"
import { GetLinkToConnectStripe } from "@/services/request/Account"
import { useQuery } from "@tanstack/react-query"
import { Info } from "lucide-react"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export function StreamerBanner() {
  const { t } = useTranslation()
  const { data: user } = useUser()

  const { refetch } = useQuery({
    queryKey: ["StripeConnect"],
    queryFn: async () => {
      const { link_to_connect } = await GetLinkToConnectStripe()
      window.location.href = link_to_connect
    },
    enabled: false,
  })

  const checkingStripeAccount = user?.stripe_pending_requirements && user?.stripe_pending_requirements?.includes(
    "individual.political_exposure",
  )
  const pendingRequirements = user?.stripe_pending_requirements && user?.stripe_pending_requirements.length > 0

  if (checkingStripeAccount || pendingRequirements) {
    return (
      <div className="md:container">
        <Alert>
          <Info className="size-4" />
          <AlertTitle>{t('streamerSignupDuoToPlay.pendingRequirements')}</AlertTitle>
          <AlertDescription className="space-y-2">
            {checkingStripeAccount ? (
              t('streamerSignupDuoToPlay.weAreChecking')
            ) : (
              <Fragment>
                <p>{t('streamerSignupDuoToPlay.weNeedMoreInfo')}</p>
                <Button variant="secondary" onClick={() => refetch()}>
                  {t('streamerSignupDuoToPlay.fulfill')}
                </Button>
              </Fragment>
            )}
          </AlertDescription>
        </Alert>
      </div>
    )
  }

  if (user?.isTalent) return null

  return (
    <div className="w-full space-y-4 md:container">
      <div className="flex h-[200px] items-center space-y-4 rounded-lg bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 p-6 text-lg">
        <div className="flex flex-col space-y-4">
          <div className="text-2xl font-light">
            {t("fanOverview.banner.title")}
          </div>

          <div>
            <Button
              asChild
              className="cursor-pointer rounded-lg bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 px-10 font-bold text-white shadow-sm ring-0 ring-pink-300 transition-all hover:shadow-md hover:ring-2 dark:focus:ring-pink-800"
            >
              <Link to={getStreamerOnboardingStartPath()} className="hover:text-white">
                {t("fanOverview.banner.button")}
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
