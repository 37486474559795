import { Card, CardContent } from "@/components/ui/card"
import { ReactNode } from "react"

type MatchesPanelProps = {
  title: ReactNode
  description?: ReactNode
  children: ReactNode
}

export function MatchesPanel({ title, description, children }: MatchesPanelProps) {
  return (
    <section className="space-y-4">
      <div className="space-y-2">
      <h3 className="flex items-center space-x-2 text-2xl font-semibold">
        {title}
      </h3>

        {description && <p className="text-sm text-muted-foreground">{description}</p>}
      </div>

      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </section>
  )
}
