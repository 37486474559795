import { MatchToPlay } from "@/components/Match/MatchToPlay"
import { Spinner } from "@/components/Spinner"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { GetMatchesByProductId } from "@/services/request/Matches"
import { TMatch } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { RefreshCw } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

export function Matches() {
  const { t } = useTranslation()
  const { duo_id } = useParams()
  const [searchMatches, setSearchMatches] = useState<TMatch[] | undefined>()

  const {
    data: matches,
    refetch,
    isFetching,
  } = useQuery({
    queryFn: async () => GetMatchesByProductId({ id: duo_id! }),
    queryKey: ["getMatchesByProductId", duo_id],
    enabled: !!duo_id,
  })

  useEffect(() => {
    if (matches) setSearchMatches(matches)
  }, [matches])

  const matchesPlayed =
    matches?.reduce((acc, curr) => {
      return curr.played ? acc + 1 : acc
    }, 0) ?? 0

  const SearchMatches = useCallback(
    (value: string) => {
      if (value) {
        const filteredMatches = matches?.filter((match) => {
          return match.nickTwitch.toLowerCase().includes(value.toLowerCase())
        })
        setSearchMatches(filteredMatches)
      } else {
        setSearchMatches(matches)
      }
    },
    [matches],
  )

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <span className="w-full text-lg font-bold">
          {t("Aside.SoldMatches")} ({matches?.length ?? 0})
        </span>
        <Button size="icon" onClick={() => refetch()} variant="secondary">
          <RefreshCw className={isFetching ? "animate-spin" : undefined} />
        </Button>
      </div>
      <div className="my-4 flex items-center gap-x-2">
        <div className="flex w-full items-center rounded-sm bg-[#232325]">
          <Input
            placeholder={t("Aside.SearchUsername")}
            onChange={(e) => SearchMatches(e.target.value)}
          />
        </div>
        <span className="text-md text-neutral m-0 items-center text-nowrap font-semibold">
          {t("Resume.players", {
            players: matchesPlayed,
            total: matches?.length ?? 0,
          })}
        </span>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Duo.table.order")}</TableHead>
            <TableHead>{t("Duo.table.status")}</TableHead>
            <TableHead>{t("Duo.table.username")}</TableHead>
            <TableHead>{t("Duo.table.player_invite")}</TableHead>
            <TableHead className="text-right">{t("Duo.table.actions")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {searchMatches ? (
            searchMatches.map((match, index) => (
              <MatchToPlay
                key={match.id}
                product_id={duo_id!}
                match={match}
                order={index}
              />
            ))
          ) : (
            <Spinner />
          )}
        </TableBody>
      </Table>
    </div>
  )
}
