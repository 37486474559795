import { useStripePromise } from "../../hook/useStripePromise"
import { ErrorScreen } from "../../components/ErrorScreen"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { PaymentVerify } from "./PaymentVerify"
import PaymentSteps from "../../components/Payment/steps/PaymentSteps"
import { PaymentOrderCard } from "../../components/Payment/PaymentOrderCard"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Helmet } from "react-helmet"
import { getAllStreamersPath, getOverviewPath, getStreamerProfilePath } from "@/lib/getRoutePath"

export function Payment() {
  const { t } = useTranslation()
  const location = useLocation()
  const { data: stripePromise } = useStripePromise()
  const { talent, product, isLoading, paymentError } = PaymentVerify()

  if (paymentError || isLoading)
    return <ErrorScreen isLoading={isLoading}>{paymentError}</ErrorScreen>

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.payment.title")}</title>
        <meta name="description" content={t("Helmet.payment.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="flex flex-col gap-y-8 p-4 md:container">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={getOverviewPath()}>Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={getAllStreamersPath()}>
                {t("TalentsOnline.title")}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={getStreamerProfilePath(talent?.name)}>
                {talent?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{product?.title}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="h-full flex-wrap items-stretch gap-4 md:flex">
          <PaymentOrderCard product={product} talentNickName={talent?.name} />
          <PaymentSteps
            product={product}
            talent={talent}
            stripePromise={stripePromise}
          />
        </div>
      </div>
    </>
  )
}
