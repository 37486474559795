import { z } from "zod"

export const ClientAccountSchema = z.object({
  name: z.string(),
  email: z.string(),
  id: z.string(),
})
export const TalentAccountSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
})

export const ItemSchema = z.object({
  id: z.string(),
  title: z.string(),
  name: z.string(),
  amount: z.number(),
  avatar: z.string(),
  external_id: z.string(),
  currency: z.string(),
  description: z.string(),
  value: z.number(),
  device: z.string().optional(),
  auto_selling: z.boolean(),
  account_talent_id: z.string(),
})

const OrderSchema = z.object({
  _id: z.string(),
  id: z.string(),
  client_account_id: z.string(),
  order_id: z.string(),
  talent_id: z.string(),
  product_id: z.string(),
  status: z.string(),
  played: z.boolean(),
  game_access: z.string(),
  item: ItemSchema,
  client_account: ClientAccountSchema,
  talent_account: TalentAccountSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
})

export type TOrder = z.infer<typeof OrderSchema>

export type TOrderRequest = {
  account_id: string
  product_id: string
  account_talent_id: string
  game_access: string
  live_message?: string
}
export type TOrderResponse = {
  order_id: string
  client_secret: string
}
