import { useTranslation } from "react-i18next"
import { TProduct } from "../../types"
import { FormatCurrency } from "../../utils/currencyHelper"
import { formatImage, PRODUCT_IMAGE_SIZE } from "../../utils/imageHelper"
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card"
import { Separator } from "../ui/separator"

type Props = {
  product?: TProduct
  talentNickName?: string
}
type PropsColumns = {
  key: string
  value: string
}

export function PaymentOrderCard({ product, talentNickName }: Props) {
  const { t } = useTranslation()

  const Columns: Array<PropsColumns> = [
    {
      key: t("CheckOut.CardOrder.PlayWith"),
      value: `@${talentNickName!}`,
    },
    {
      key: t("CheckOut.CardOrder.quantity"),
      value: t("Payment.Success.vacancy", { quantity: 1 }),
    },
  ]

  return (
    <Card className="min-w-[300px]">
      <CardHeader>
        <CardTitle>{t("CheckOut.YouAreBuying")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex justify-center">
            <img
              height={PRODUCT_IMAGE_SIZE.height}
              width={PRODUCT_IMAGE_SIZE.width}
              src={formatImage(product?.avatar!)}
              alt={`Avatar of ${product?.name}`}
            />
          </div>
          <div className="text-neutral flex flex-col gap-y-2 pt-2">
            <div className="flex flex-col">
              <div className="text-lg font-bold">{product?.title}</div>
            </div>

            {Columns.map((column, index) => (
              <div
                key={index}
                className="flex h-[1.375rem] items-center justify-between space-x-4"
              >
                <span className="m-0 flex text-nowrap text-muted-foreground">
                  {column.key}
                </span>
                <span className="truncate font-bold">{column.value}</span>
              </div>
            ))}
          </div>
          <Separator />
          <div className="flex items-center justify-between text-lg">
            <div>Total</div>
            <div className="font-bold">
              {FormatCurrency({
                value: product!.value,
                currency: product?.currency,
              })}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
