import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { TMatch } from "../../types"
import { useState } from "react"
import Whisper from "../Whisper"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { TwitchIcon } from "../TwitchIcon"
import { Copy, Gamepad2, Info } from "lucide-react"
import { Button } from "@/components/ui/button"
import { toast } from "sonner"

type DuoClientInviteModalProps = {
  isModalOpen: boolean
  handleCancel: () => void
  clientMatchData: Partial<TMatch>
}
export function MatchInviteModal({
  isModalOpen,
  handleCancel,
  clientMatchData,
}: DuoClientInviteModalProps) {
  const { t } = useTranslation()
  const [openWhisper, setOpenWhisper] = useState(false)

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          handleCancel()
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Matches.Modal.Invite.Invite")}</DialogTitle>
        </DialogHeader>

        <div className="item-center flex flex-col justify-around gap-2">
          <div className="flex w-full items-center gap-4 rounded bg-secondary p-4">
            <div className="flex size-10 items-center justify-center rounded bg-[#9146FF] text-white">
              <TwitchIcon width={24} />
            </div>
            <div className="h-10 gap-1 font-sans">
              <div className="text-xs text-muted-foreground">
                {t("Matches.Modal.Invite.NicknameTwitch")}
              </div>
              <div className="flex items-center gap-2">
                <div className="text-base font-bold">
                  @{clientMatchData.nickTwitch}
                </div>
                <CopyToClipboard
                  text={clientMatchData.nickTwitch || ""}
                  onCopy={() =>
                    toast.success(
                      t("Matches.Modal.Invite.NicknameTwitchCopied"),
                    )
                  }
                >
                  <button>
                    <Copy size={13} />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div className="flex w-full items-center gap-4 rounded bg-secondary p-4">
            <div className="flex size-10 items-center justify-center rounded bg-slate-100 text-slate-900">
              <Gamepad2 />
            </div>
            <div className="h-10 gap-1 font-sans">
              <div className="text-xs text-muted-foreground">
                {t("Matches.Modal.Invite.Username")}
              </div>
              <div className="flex items-center gap-2">
                <div className="text-base font-bold">{clientMatchData.tag}</div>
                <CopyToClipboard
                  text={clientMatchData.tag || ""}
                  onCopy={() =>
                    toast.success(
                      t("Matches.Modal.Invite.NicknameTwitchCopied"),
                    )
                  }
                >
                  <button>
                    <Copy size={13} />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>

        {openWhisper ? (
          <Whisper
            nickName={clientMatchData.nickTwitch!}
            onCancel={() => setOpenWhisper(false)}
          />
        ) : (
          <Button onClick={() => setOpenWhisper(!openWhisper)}>
            {t("Matches.Modal.Invite.SendWhisper")}
          </Button>
        )}

        <div className="flex items-center gap-1">
          <Info size={13} />
          <p className="text-sm text-muted-foreground">
            {t("Matches.Modal.Invite.CheckDailyLimit")}
          </p>
        </div>
      </DialogContent>
    </Dialog>
  )
}
