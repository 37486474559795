import {
  InstagramIcon,
  TwitchIcon,
  TikTokIcon,
  YoutubeIcon,
  LinkedinIcon,
  DiscordIcon,
} from "../../Icons"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { env } from "@/utils/env"

export function Footer() {
  const { t } = useTranslation()
  const socialLinks = [
    { icon: InstagramIcon, href: env.DUO_INSTAGRAM, label: "Instagram" },
    { icon: TikTokIcon, href: env.DUO_TIKTOK, label: "TikTok" },
    { icon: YoutubeIcon, href: env.DUO_YOUTUBE, label: "YouTube" },
    { icon: TwitchIcon, href: env.DUO_TWITCH, label: "Twitch" },
    { icon: LinkedinIcon, href: env.DUO_LINKEDIN, label: "LinkedIn" },
    { icon: DiscordIcon, href: env.DUO_DISCORD, label: "Discord" },
  ]

  return (
    <footer className="w-full border-spacing-2 border-solid border-red-500 bg-sidebar py-8">
      <div className="container mx-auto px-4">
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          <div className="space-y-4">
            <h2 className="text-xl font-bold tracking-tight">DUOTOPLAY</h2>
            <p className="text-sm text-gray-400">{t("Footer.description")}</p>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold">{t("Footer.legal.title")}</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="#" className="transition-colors hover:text-gray-400">
                  {t("Footer.legal.terms")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold">{t("Footer.support.title")}</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="#" className="transition-colors hover:text-gray-400">
                  {t("Footer.support.faq")}
                </Link>
              </li>
              <li>
                <Link
                  to={env.DUO_DISCORD}
                  className="transition-colors hover:text-gray-400"
                >
                  {t("Footer.support.contact")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="font-semibold">{t("Footer.followUs")}</h3>
            <div className="flex flex-wrap gap-4">
              {socialLinks.map((social) => (
                <Link
                  key={social.label}
                  to={social.href}
                  className="transition-colors hover:text-gray-400"
                  aria-label={social.label}
                >
                  <social.icon className="h-5 w-5" />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-col items-center justify-between gap-4 border-t border-gray-600 pt-8 text-sm text-gray-400 sm:flex-row">
          <p className="uppercase">
            © 2024, DUOTOPLAY. {t("RightsReserved")}.
          </p>
          <p className="rounded-full bg-zinc-900 px-3 py-1 text-xs">
            BETA VERSION
          </p>
        </div>
      </div>
    </footer>
  )
}
