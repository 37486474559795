import { getAllStreamersPath, getLoginPath, getStreamerProfilePath } from "@/lib/getRoutePath"
import { ArrowRight } from "lucide-react"
import { Link } from "react-router-dom"
import { TTalent } from "../talents"
import { useTranslation } from "react-i18next"

export function Community({ streamers }: { streamers: TTalent[] }) {
  const { t } = useTranslation()
  return (
    <section>
      <div className="h-screen">
        <h2 className="mb-16 text-4xl font-bold text-white">
          {t("LP.Community.title")}
        </h2>
        <div className="relative flex h-[80vh]">
          <div className="absolute bottom-0 z-10 flex h-[20%] w-full items-end justify-center bg-gradient-to-t from-black/90 via-black/50 to-transparent">
            <Link
              to={getAllStreamersPath()}
              className="flex items-center gap-2 rounded-lg bg-primary px-6 py-3 text-sm font-bold uppercase text-black transition-all hover:scale-105 hover:text-black"
            >
              {t("LP.Community.DuoStreamers")} <ArrowRight className="mr-2" />
            </Link>
          </div>
          <div className="relative flex w-full flex-wrap gap-6 overflow-hidden p-2">
            {streamers.map((streamer) => (
              <Link to={getStreamerProfilePath(streamer.name)} key={streamer.id}>
                <div className="relative h-[16rem] w-[12rem] overflow-hidden rounded-2xl bg-[#1A1A1A]">
                  <div
                    style={{
                      backgroundImage: `url(${streamer.avatar})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="absolute inset-0 z-0 transition-transform duration-300 ease-out will-change-transform hover:scale-110"
                    role="img"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-28 flex h-40 justify-between overflow-hidden rounded-xl bg-[#107272]">
        <span className="p-10 text-3xl">{t("LP.CTA")}</span>
        <Link
          className="flex h-full w-1/5 items-center justify-center rounded-none bg-[#13A8A8] text-xl font-bold text-black transition-all hover:scale-110 hover:text-black"
          to={getLoginPath()}
        >
          {t("LP.CTAButton")} <ArrowRight />
        </Link>
      </div>
    </section>
  )
}
