import { useContext, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { AuthContext } from "../../context/auth"
import { publicApi } from "../../services/api"
import { Helmet } from "react-helmet"
import { Button } from "@/components/ui/button"
import { TwitchIcon } from "@/components/TwitchIcon"
import { getOverviewPath, getTermsPath } from "@/lib/getRoutePath"
import { ChangeLanguage } from "@/components/ChangeLanguage"

export function Login() {
  const {
    token,
    user: { userId },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  const navigation = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (token && userId) {
      navigation(getOverviewPath())
    }
  }, [token, userId, navigation])

  async function handleAuthLink(type: string) {
    const { data } = await publicApi.get(`/authorize/third-party?type=${type}`)
    window.location.href = data.uri
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.login.title")}</title>
        <meta name="description" content={t("Helmet.login.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div>
        <div className="absolute flex w-full items-center justify-between pt-4 text-white">
          <span className="pl-8 text-xl font-bold uppercase tracking-widest">
            DuoToPlay
          </span>
          <div className="pr-8"><ChangeLanguage /></div>
        </div>
        <div className="flex h-screen flex-col md:flex-row">
          <img
            width="100%"
            height="100%"
            src="/img/login-banner.png"
            alt="Login gamer"
            className="h-1/3 md:h-screen md:w-3/5"
          />
          <div className="flex flex-col justify-center bg-background px-24 text-white md:w-2/5">
            <div className="mt-[2vh] flex w-full flex-col justify-center md:mt-0">
              <h1 className="m-0 w-full text-4xl leading-[3rem]">
                {t("login.title")}
              </h1>
              <p className="mb-8 mt-4 text-base font-light">
                {t("login.description")}
              </p>
              <Button onClick={() => handleAuthLink("twitch")}>
                <span className="text-white">
                  <TwitchIcon width={24} />
                </span>
                <span>{t("login.twitch")}</span>
              </Button>
            </div>
            <p className="m-0 mt-6 text-base font-light">
              <Trans i18nKey="login.agreeTerms">
                <Link
                  to={getTermsPath()}
                  className="text-neutral font-extrabold underline"
                />
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
