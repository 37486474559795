import { Talents } from "@/pages/talents"
import { StreamerBanner } from "./StreamerBanner"

export function FanOverview() {
  return (
    <div className="space-y-10 pb-20">
      <StreamerBanner />
      <Talents />
    </div>
  )
}
