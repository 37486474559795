import { Spinner } from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import { getOverviewPath } from "@/lib/getRoutePath";
import { queryClient } from "@/lib/reactQuery";
import { DeleteProduct } from "@/services/request/Product";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { toast } from "sonner";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogDescription,
} from "@/components/ui/alert-dialog"

export function DeleteDuoButton({ duoId }: { duoId: string }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isDeleting, setIsDeleting] = useState(false)

  const handleClickDelete = async () => {
    try {
      await DeleteProduct(duoId)
      queryClient.invalidateQueries({ queryKey: ['getProducts'] })
      toast.success(t("deleteDuo.success"))
      navigate(getOverviewPath())
    } catch (error) {
      toast.error(t("deleteDuo.error"))
    }
  }

  return (
    <Fragment>
      <Button variant="destructive" onClick={() => setIsDeleting(true)}>
        {isDeleting && <Spinner />}
        {t('createMatch.delete')}
      </Button>

      <ConfirmDeleteDuoDialog
        isModalOpen={isDeleting}
        handleOk={handleClickDelete}
        handleCancel={() => setIsDeleting(false)}
      />
    </Fragment>
  )
}

type ConfirmDeleteDuoDialogProps = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
}

export function ConfirmDeleteDuoDialog({
  isModalOpen,
  handleOk,
  handleCancel,
}: ConfirmDeleteDuoDialogProps) {
  const { t } = useTranslation()

  return (
    <AlertDialog
      open={isModalOpen}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          handleCancel()
        }
      }}
    >
      <AlertDialogPortal>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("deleteDuo.modal.title")}
            </AlertDialogTitle>
            <AlertDialogDescription className="text-pretty">
              {t("deleteDuo.modal.description")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t("deleteDuo.modal.cancel")}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleOk} variant="destructive">
              {t("deleteDuo.modal.confirm")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  )
}
