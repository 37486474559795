import { TWhisperDefault } from "../../../types"
import { privateApi } from "../../api"

export async function PostWhisper(content: {
  message: string
  client_account_id: string
}): Promise<void> {
  await privateApi.post("/whisper", content)
}

export async function PostDefaultWhisper({
  userId,
  content,
}: {
  userId: string
  content: TWhisperDefault
}): Promise<void> {
  await privateApi.patch("/account/" + userId, content)
}
