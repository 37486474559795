import { TMatch } from "../../../types"
import { privateApi } from "../../api"

export async function GetMatchesByProductId({
  id,
}: {
  id: string
}): Promise<TMatch[]> {
  const res = await privateApi.get<TMatch[]>(`/matches`, {
    params: {
      product_id: id,
    },
  })
  return res.data
}

export async function UpdateMatchToPlay({
  id,
  status,
}: {
  id: string
  status: boolean
}) {
  const res = await privateApi.patch(`/matches`, { id, status })
  return res.data
}
