import { z } from "zod"

export const WhisperSchema = z.object({
  message: z.string().max(300),
})

export const WhisperDefaultSchema = z.object({
  whisper_message: z.string().max(300),
})

export type TWhisperDefault = z.infer<typeof WhisperDefaultSchema>

export type TWhisper = z.infer<typeof WhisperSchema>
