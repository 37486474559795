import { ReactNode } from "react"

type OnboardingStepDescriptionProps = {
  icon: ReactNode
  children: ReactNode
  cta: ReactNode
}

export function OnboardingStepDescription({ icon, children, cta }: OnboardingStepDescriptionProps) {
  return (
    <div className="text-base text-balance max-w-[300px] m-auto text-center space-y-10">
      <div className="flex justify-center">
        {icon}
      </div>

      <div className="space-y-4">
        {children}

        {cta}
      </div>
    </div>
  )
}