import { Button } from "@/components/ui/button"
import { ArrowLeft } from "lucide-react"
import { Link } from "react-router-dom"

export function BackButton({ to }: { to: string }) {
  return (
    <Button size="icon" variant="secondary" asChild>
      <Link to={to} className="hover:text-foreground">
        <ArrowLeft />
      </Link>
    </Button>
  )
}
