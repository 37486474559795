import { ReactNode } from "react"
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar"
import { Toaster as Sonner } from "@/components/ui/sonner"
import { Toaster } from "@/components/ui/toaster"
import { Footer } from "../footers/Footer"
import { Sidebar } from "./sideBar"

type Props = {
  children: ReactNode
}

export const LayoutView = ({ children }: Props) => {
  return (
    <div>
      <SidebarProvider>
        <Sidebar />
        <div className="flex min-h-full grow flex-col">
          <div className="flex-grow p-2 md:container md:p-6">
            <div className="fixed top-0 z-10 flex h-[50px] w-full items-center gap-4 bg-background md:hidden">
              <SidebarTrigger />
              <h1 className="text-lg font-bold text-white">DUOTOPLAY</h1>
            </div>

            <div className="pt-[50px] md:pt-0">{children}</div>
          </div>
          <Sonner position="top-center" />
          <Toaster />
          <Footer />
        </div>
      </SidebarProvider>
    </div>
  )
}
