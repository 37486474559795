import { SlideEffect } from "@/components/SlideEffect"
import { TwitchIcon } from "@/components/TwitchIcon"
import { GamepadIcon, ShieldIcon, TimerIcon, Banknote } from "lucide-react"
import { useTranslation } from "react-i18next"

export function Advantages() {
  const { t } = useTranslation()

  return (
    <section className="container mx-auto mb-24 overflow-hidden px-4 py-24">
      <h2 className="mb-16 text-4xl font-bold text-white">
        {t("LP.Advantages.title")}
      </h2>
      <div className="t grid auto-rows-fr grid-cols-1 gap-6 md:grid-cols-3">
        <SlideEffect
          direction="left"
          className="h-[18rem] rounded-2xl bg-[#1A1A1A] p-6"
        >
          <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-lg bg-[#FFB800]">
            <GamepadIcon className="h-6 w-6 text-black" />
          </div>
          <h3 className="mb-3 text-xl font-bold text-white">
            {t("LP.Advantages.multipleGames.title")}
          </h3>
          <p className="text-white/70">
            {t("LP.Advantages.multipleGames.description")}
          </p>
        </SlideEffect>
        {/* Exclusive Interactivity Card */}

        <SlideEffect
          direction="right"
          className="h-[18rem] rounded-2xl bg-[#1A1A1A] p-6 md:col-start-1"
        >
          <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-lg bg-[#00B3B3]">
            <ShieldIcon className="h-6 w-6 text-black" />
          </div>
          <h3 className="mb-3 text-xl font-bold text-white">
            {t("LP.Advantages.securityGuaranteed.title")}
          </h3>
          <p className="text-white/70">
            {t("LP.Advantages.securityGuaranteed.description")}
          </p>
        </SlideEffect>

        {/* Twitch Integration Card */}
        <SlideEffect
          direction="left"
          className="h-[18rem] rounded-2xl bg-[#1A1A1A] p-6 md:col-span-2 md:col-start-2"
        >
          <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-lg bg-[#9146FF]">
            <TwitchIcon className="h-6 w-6 text-black" />
          </div>
          <h3 className="mb-3 text-xl font-bold text-white">
            {t("LP.Advantages.twitchIntegration.title")}
          </h3>
          <p className="text-white/70">
            {t("LP.Advantages.twitchIntegration.description")}
          </p>
        </SlideEffect>

        {/* Real-time Interactivity Card */}
        <SlideEffect
          direction="right"
          className="h-[18rem] rounded-2xl bg-[#1A1A1A] p-6 md:col-start-2"
        >
          <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-lg bg-[#FF6B00]">
            <TimerIcon className="h-6 w-6 text-black" />
          </div>
          <h3 className="mb-3 text-xl font-bold text-white">
            {t("LP.Advantages.exclusiveInteractivity.title")}
          </h3>
          <p className="text-white/70">
            {t("LP.Advantages.exclusiveInteractivity.description")}
          </p>
        </SlideEffect>

        {/* Unlimited Earnings Card */}
        <SlideEffect
          direction="right"
          className="h-[18rem] rounded-2xl bg-[#1A1A1A] p-6 md:col-start-3"
        >
          <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-lg bg-[#4CAF50]">
            <Banknote className="h-6 w-6 text-black" />
          </div>
          <h3 className="mb-3 text-xl font-bold text-white">
            {t("LP.Advantages.UnlimitedEarnings.title")}
          </h3>
          <p className="text-white/70">
            {t("LP.Advantages.UnlimitedEarnings.description")}
          </p>
        </SlideEffect>
      </div>
    </section>
  )
}
