import { TProduct } from "../../../types"
import { privateApi } from "../../api"

export async function GetProductsByAccount(
  account_id: string,
): Promise<TProduct[]> {
  const res = await privateApi.get<TProduct[]>("/products", {
    params: {
      account_id: account_id,
      order: "desc",
    },
  })
  return res.data
}
export async function GetEnabledProductsByAccount(
  account_id: string,
): Promise<TProduct[]> {
  const res = await privateApi.get<TProduct[]>("/products", {
    params: {
      account_id: account_id,
      order: "desc",
      enable_to_sell: true,
    },
  })
  return res.data
}

export async function GetProduct(id: string): Promise<TProduct> {
  const res = await privateApi.get<TProduct[]>(`/products?id=${id}`)
  return res.data[0]
}

export async function PostProduct(content: Partial<TProduct>): Promise<void> {
  await privateApi.post("/products", content)
}
export async function PutProduct({
  id,
  content,
}: {
  id: string
  content: Partial<TProduct>
}): Promise<void> {
  await privateApi.patch(`/products/${id}`, content)
}

export async function PutProductEnableToSell({
  id,
  enable_to_sell,
}: {
  id: string
  enable_to_sell: boolean
}): Promise<void> {
  return privateApi.patch(`/products/${id}`, { enable_to_sell: enable_to_sell })
}

export async function DeleteProduct(id: string): Promise<void> {
  await privateApi.delete(`/products/${id}`)
}
