import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { TPlayer, PlayerSchema } from "../../../types"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import clsx from "clsx"
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"

type Props = {
  setData: Dispatch<SetStateAction<TPlayer | undefined>>
  disabled?: boolean
}
export function FirstStep({ setData, disabled }: Props) {
  const { t } = useTranslation()

  const form = useForm<TPlayer>({
    resolver: zodResolver(PlayerSchema(t)),
  })

  const handleSave = async (personalData: Partial<TPlayer>) => {
    setData(personalData as TPlayer)
  }

  return (
    <Form {...form}>
      <form
        className={clsx(
          "w-full space-y-2 transition-all duration-300",
          disabled && "animate-fade-out hidden",
        )}
        onSubmit={form.handleSubmit(handleSave)}
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-balance text-foreground">
                {t("CheckOut.GameAccount.Username.title")}
              </FormLabel>
              <FormDescription>
                {t("CheckOut.GameAccount.Username.Tooltip")}
              </FormDescription>
              <Input {...field} placeholder="FalleN" />
              <FormMessage className="text-red-400" />
            </FormItem>
          )}
        />

        <div className="flex justify-end">
          <Button type="submit">{t("CheckOut.GameAccount.Advance")}</Button>
        </div>
      </form>
    </Form>
  )
}
