import { useTranslation } from "react-i18next"

import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { StreamerPerformance } from "./StreamerPerformance"
import { StreamerProducts } from "./StreamerProducts"
import { StreamerPublicProfile } from "./StreamerPublicProfile"
import { CopyObsLink } from "@/pages/profile/CopyObsLink"

export function StreamerOverview() {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.resume.title")}</title>
        <meta name="description" content={t("Helmet.resume.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>

      <div className="flex flex-row justify-between overflow-hidden">
        <div className="flex flex-1 flex-col space-y-8 overflow-hidden">
          <StreamerPerformance />
          <StreamerProducts />
          <StreamerPublicProfile />
          <CopyObsLink />
        </div>
      </div>
    </>
  )
}
