import { useTranslation } from "react-i18next";
import { StreamerOnboardingSteps } from "../StreamerOnboardingSteps";
import { OnboardingStepDescription } from "../OnboardingStepDescription";
import { useQuery } from "@tanstack/react-query";
import { GetLinkToConnectStripe } from "@/services/request/Account";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { HandCoins, Wallet } from "lucide-react";
import { Spinner } from "@/components/Spinner";

export default function StreamerOnboardingStripe() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const { refetch } = useQuery({
    queryKey: ["StripeConnect"],
    queryFn: async () => {
      const { link_to_connect } = await GetLinkToConnectStripe()
      window.location.href = link_to_connect
    },
    enabled: false,
  })

  return (
    <div className="md:container space-y-20">
      <StreamerOnboardingSteps currentStep={2} />

      <OnboardingStepDescription
        icon={<HandCoins className="text-emerald-500" />}
        cta={
          <Button
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true)
              refetch()
            }}>
            {isLoading ? <Spinner /> : <Wallet />}
            {t('streamerOnboarding.steps.stripe.cta')}
          </Button>
        }>
        <p>{t('streamerOnboarding.steps.stripe.description')}</p>
      </OnboardingStepDescription >
    </div >
  );
}