import { useTranslation } from "react-i18next"
import { Badge } from "@/components/ui/badge"
import { LiveIcon } from "../LiveIcon"

export function ProductOnline() {
  const { t } = useTranslation()

  return (
    <Badge className="flex items-center gap-2 hover:bg-primary">
      <LiveIcon />
      <span>{t("onlineNow")}</span>
    </Badge>
  )
}
