import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { GetAccountByNickname } from "../services/request/Account"

export function useTalent() {
  const { talent_nickname } = useParams()

  return useQuery({
    queryKey: ["talent", talent_nickname],
    queryFn: async () => {
      if (talent_nickname) {
        try {
          const talent = await GetAccountByNickname(talent_nickname)
          return talent
        } catch (error) {
          return undefined
        }
      }
      return undefined
    },
  })
}
