import * as React from "react"

function TopRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={755}
      height={533}
      viewBox="0 0 755 533"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M473.178 256.323v-6.014l-4.263 4.241-266.308 264.919c-14.138 14.064-36.809 14.603-51.6 1.227L14.954 397.657c-15.938-14.413-16.529-39.245-1.294-54.399L345.233 13.414A37.5 37.5 0 01371.68 2.5H715c20.711 0 37.5 16.79 37.5 37.5v164.338a37.503 37.503 0 01-11.053 26.586L537.125 434.18c-23.652 23.528-63.947 6.776-63.947-26.586V256.323z"
        stroke="url(#paint0_radial_2118_1453)"
        strokeWidth={5}
      />
      <defs>
        <radialGradient
          id="paint0_radial_2118_1453"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 274 -386.5 0 368.5 274)"
        >
          <stop stopColor="#2E2E2E" />
          <stop offset={1} stopColor="#0F0F10" />
        </radialGradient>
      </defs>
    </svg>
  )
}
function BottomLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={760}
      height={325}
      viewBox="0 0 760 325"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M549.803 116.346V23.525c0-17.852 21.613-26.76 34.192-14.093l167.197 168.373A20 20 0 01757 191.897V305c0 11.046-8.954 20-20 20H23.046c-17.784 0-26.723-21.473-14.192-34.092L176.33 122.254a20.002 20.002 0 0114.192-5.908h359.281z"
        stroke="url(#paint0_radial_2118_1451)"
        strokeWidth={5}
      />
      <defs>
        <radialGradient
          id="paint0_radial_2118_1451"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 175 -391 0 366 150)"
        >
          <stop stopColor="#2E2E2E" />
          <stop offset={1} stopColor="#0F0F10" />
        </radialGradient>
      </defs>
    </svg>
  )
}

function BottomRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={219}
      height={401}
      viewBox="0 0 219 401"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.908 186.437L184.78 8.602C197.346-4.105 219 4.794 219 22.666v355.668c0 17.872-21.654 26.771-34.22 14.064L8.908 214.563c-7.706-7.792-7.706-20.334 0-28.126z"
        stroke="url(#paint0_radial_2118_1452)"
        strokeWidth={5}
      />
      <defs>
        <radialGradient
          id="paint0_radial_2118_1452"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 226.5 -112 0 107 200.5)"
        >
          <stop stopColor="#2E2E2E" />
          <stop offset={1} stopColor="#0F0F10" />
        </radialGradient>
      </defs>
    </svg>
  )
}
function TopRightShadowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={809}
      height={587}
      viewBox="0 0 809 587"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_2118_1456)">
        <path
          d="M527.178 256.323v-6.014l-4.263 4.241-266.308 264.919c-14.138 14.064-36.809 14.603-51.6 1.227L68.954 397.657c-15.938-14.413-16.529-39.245-1.294-54.399L399.233 13.414A37.5 37.5 0 01425.68 2.5H769c20.711 0 37.5 16.79 37.5 37.5v164.338a37.503 37.503 0 01-11.053 26.586L591.125 434.18c-23.652 23.528-63.947 6.776-63.947-26.586V256.323z"
          stroke="url(#paint0_radial_2118_1456)"
          strokeWidth={5}
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2118_1456"
          x={0.106445}
          y={-54}
          width={862.894}
          height={640.883}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={27}
            result="effect1_foregroundBlur_2118_1456"
          />
        </filter>
        <radialGradient
          id="paint0_radial_2118_1456"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 274 -386.5 0 422.5 274)"
        >
          <stop stopColor="#2E2E2E" />
          <stop offset={1} stopColor="#0F0F10" />
        </radialGradient>
      </defs>
    </svg>
  )
}
function BottomLeftShadowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={839}
      height={379}
      viewBox="0 0 839 379"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_2118_1457)">
        <path
          d="M574.803 170.346V77.525c0-17.852 21.613-26.76 34.192-14.093l167.197 168.373A20 20 0 01782 245.897V359c0 11.046-8.954 20-20 20H48.046c-17.784 0-26.723-21.473-14.192-34.092L201.33 176.254a20.002 20.002 0 0114.192-5.908h359.281z"
          stroke="url(#paint0_radial_2118_1457)"
          strokeWidth={5}
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2118_1457"
          x={-28.499}
          y={0.979492}
          width={866.999}
          height={434.521}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={27}
            result="effect1_foregroundBlur_2118_1457"
          />
        </filter>
        <radialGradient
          id="paint0_radial_2118_1457"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 175 -391 0 391 204)"
        >
          <stop stopColor="#2E2E2E" />
          <stop offset={1} stopColor="#0F0F10" />
        </radialGradient>
      </defs>
    </svg>
  )
}
function BottomRightShadowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={273}
      height={460}
      viewBox="0 0 273 460"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_2118_1458)">
        <path
          d="M62.908 240.437L238.78 62.602C251.346 49.895 273 58.794 273 76.666v355.668c0 17.872-21.654 26.771-34.22 14.064L62.908 268.563c-7.706-7.792-7.706-20.334 0-28.126z"
          stroke="url(#paint0_radial_2118_1458)"
          strokeWidth={5}
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2118_1458"
          x={0.628418}
          y={0.120605}
          width={328.872}
          height={508.759}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={27}
            result="effect1_foregroundBlur_2118_1458"
          />
        </filter>
        <radialGradient
          id="paint0_radial_2118_1458"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 226.5 -112 0 161 254.5)"
        >
          <stop stopColor="#2E2E2E" />
          <stop offset={1} stopColor="#0F0F10" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export { TopRightIcon, BottomLeftIcon, BottomRightIcon, BottomRightShadowIcon, TopRightShadowIcon, BottomLeftShadowIcon }
