import { cn } from "@/lib/utils"
import { useInView } from "motion/react"
import { useRef } from "react"
import { ClassNameValue } from "tailwind-merge"

export function SlideEffect({
  direction,
  children,
  className,
}: {
  direction: "left" | "right"
  children: React.ReactNode
  className?: ClassNameValue
}) {
  const ref = useRef(null)
  const isInView = useInView(ref)
  return (
    <div
      ref={ref}
      className={cn("transition-all duration-1000", className, {
        "-translate-x-full": direction === "left" && !isInView,
        "translate-x-full": direction === "right" && !isInView,
      })}
    >
      {children}
    </div>
  )
}
