export const Currencies = [
  {
    value: "brl",
    symbol: "R$",
  },
  {
    value: "usd",
    symbol: "$",
  },
]
