import { useMutation } from "@tanstack/react-query"
import { Button } from "@/components/ui/button"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { queryClient } from "../../lib/reactQuery"
import { GetAccountPerformance } from "../../services/request/Account"
import { UpdateMatchToPlay } from "../../services/request/Matches"
import { TAccountPerformance, TMatch } from "../../types"
import { MatchInviteModal } from "./MatchInviteModal"
import { MatchUninvitedModal } from "./MatchUninviteModal"
import { CheckCheck, Clock, Undo } from "lucide-react"
import { TableCell, TableRow } from "@/components/ui/table"

interface MatchToPlayProps {
  match: TMatch
  order: number
  product_id: string
}

export function MatchToPlay({ match, order, product_id }: MatchToPlayProps) {
  const [isModalInviteMatchOpen, setIsModalInviteMatchOpen] = useState(false)
  const [isModalUninviteMatchOpen, setIsModalUninviteMatchOpen] =
    useState(false)
  const { t } = useTranslation()

  const { mutateAsync: updateMatchmatchMutate } = useMutation({
    mutationFn: UpdateMatchToPlay,
    onSuccess: () => {
      const cached = queryClient.getQueryData<TMatch[]>([
        "getMatchesByProductId",
        product_id,
      ])
      queryClient.setQueryData<TMatch[]>(
        ["getMatchesByProductId", product_id],
        cached?.map((client) => {
          if (client.id === match.id) {
            return {
              ...client,
              played: !client.played,
            }
          }
          return client
        }),
      )
    },
  })

  const { mutateAsync: updatePerformanceMutate } = useMutation({
    mutationFn: GetAccountPerformance,
    onSuccess: () => {
      const cached = queryClient.getQueryData<TAccountPerformance>([
        "getAccountPerformance",
      ])
      if (cached) {
        queryClient.setQueryData<TAccountPerformance>(
          ["getAccountPerformance"],
          {
            ...cached,
            matches_played: match.played
              ? cached.matches_played + 1
              : cached.matches_played - 1,
          },
        )
      }
    },
  })

  const handleInviteMatchOk = () => {
    updateMatchmatchMutate({ id: match.id, status: true })
    setIsModalInviteMatchOpen(false)
    updatePerformanceMutate()
  }

  const handleCancelInviteMatch = () => {
    updateMatchmatchMutate({ id: match.id, status: false })
    setIsModalUninviteMatchOpen(false)
    updatePerformanceMutate()
  }

  return (
    <TableRow>
      <TableCell>{order + 1}</TableCell>
      <TableCell>
        {match.played ? (
          <span className="text-green-500">
            <CheckCheck size={13} />
          </span>
        ) : (
          <span className="text-muted-foreground">
            <Clock size={13} />
          </span>
        )}
      </TableCell>
      <TableCell>
        <span>@{match.nickTwitch}</span>
      </TableCell>
      <TableCell>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsModalInviteMatchOpen(true)}
        >
          {t("ToInvite")}
        </Button>
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-end gap-2">
          {match.played ? (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsModalUninviteMatchOpen(true)}
            >
              <Undo />
              {t("Matches.Modal.MarkAsUnplayed")}
            </Button>
          ) : (
            <Button variant="secondary" size="sm" onClick={handleInviteMatchOk}>
              <CheckCheck />
              {t("Matches.Modal.MarkAsPlayed")}
            </Button>
          )}
        </div>
      </TableCell>
      <MatchInviteModal
        isModalOpen={isModalInviteMatchOpen}
        handleCancel={() => setIsModalInviteMatchOpen(false)}
        clientMatchData={match}
      />
      <MatchUninvitedModal
        isModalOpen={isModalUninviteMatchOpen}
        handleOk={handleCancelInviteMatch}
        handleCancel={() => setIsModalUninviteMatchOpen(false)}
      />
    </TableRow>
  )
}
