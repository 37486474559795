import ReactMarkdown, { Components } from "react-markdown"

const components: Components = {
  h3: ({ children }) => {
    return <h3 className="mt-5 text-2xl font-bold">{children}</h3>
  },
  p: ({ children }) => {
    return (
      <p className="text-wrap text-base leading-normal text-foreground">
        {children}
      </p>
    )
  },
}

export function Markdown({ text }: { text?: string }) {
  return <ReactMarkdown children={text} components={components} />
}
