import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

export function InitialHelmet() {
  const { t, i18n } = useTranslation()
  return (
    <Helmet titleTemplate="%s - DuoToPlay">
      <meta charSet="utf-8" />
      <title>DuoToPlay</title>
      <html lang={i18n.language === "pt" ? "pt-BR" : "en"} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={t("Helmet.resume.title")} />
      <meta
        property="og:description"
        content={t("Helmet.resume.description")}
      />
      <link rel="preconnect" />
    </Helmet>
  )
}
