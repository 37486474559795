import { env } from "./env"

export const PRODUCT_IMAGE_SIZE = {
  width: 300,
  height: 400,
}

export function formatImage(
  image: string,
  width = PRODUCT_IMAGE_SIZE.width,
  height = PRODUCT_IMAGE_SIZE.height,
) {
  return image
    .replace("{width}", width.toString())
    .replace("{height}", height.toString())
}

export const CreateProductImageURL = (
  game_id: string,
  width = PRODUCT_IMAGE_SIZE.width,
  height = PRODUCT_IMAGE_SIZE.height,
) => {
  const game_url = env.GAME_IMAGE_TWITCH_URL.replace(
    "{width}",
    width.toString(),
  )
    .replace("{height}", height.toString())
    .replace("{game_id}", game_id)

  return game_url
}
