export function ChatBubble(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={210}
      height={183}
      viewBox="0 0 210 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.5 0h209v149.493h-88.155L103.714 183l-19.1-33.507H.5V0z"
        fill="#1B1B1B"
      />
    </svg>
  )
}
