import { TOrder, TOrderRequest, TOrderResponse } from "../../../types"
import { privateApi } from "../../api"

type PropsGetOrder = {
  id: string
  isClient: boolean
}
export async function GetOrder({
  id,
  isClient,
}: PropsGetOrder): Promise<TOrder> {
  const res = await privateApi.get<TOrder[]>(`/order`, {
    params: { id, isClient },
  })
  return res.data[0]
}
export async function PostOrder(
  content: TOrderRequest,
): Promise<TOrderResponse> {
  const res = await privateApi.post<TOrderResponse>("/order", content)
  return res.data
}

type PropsPatchOrderNotification = {
  payment_intent: string
  order_id: string
}
export async function PatchOrderNotification({
  payment_intent,
  order_id,
}: PropsPatchOrderNotification): Promise<TOrder> {
  const res = await privateApi.patch<TOrder>(`/order/${order_id}`, {
    payment_intent,
  })
  return res.data
}
