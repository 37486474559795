import { TProduct } from "@/types"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Fragment } from "react/jsx-runtime"
import { ProductClientCard } from "@/components/Product/ProductClientCard"
import { EditButton } from "@/components/EditButton"
import { useTranslation } from "react-i18next"
import { getEditDuoPath } from "@/lib/getRoutePath"

export function ProductDetails({
  product,
  edit,
}: {
  product: Partial<TProduct>
  edit?: boolean
}) {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle>
            <div className="flex items-center justify-between space-x-2">
              <div className="">{t("Duo.preview")}</div>
              {edit && <EditButton to={getEditDuoPath(product.id)} />}
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ProductClientCard
            product={
              {
                ...product,
                value: product.value || 0,
              } as TProduct
            }
            execute={() => {}}
          />
        </CardContent>
      </Card>
    </Fragment>
  )
}
