import { publicApi } from "../../api"

export type TStripeConfig = {
  key: string
}

export async function GetStripeConfig(): Promise<TStripeConfig> {
  const res = await publicApi.get<TStripeConfig>("/config")
  return res.data
}
