import { AuthProvider } from "../../context/auth"
import { IsUserOnlineOnTwitchProvider } from "../../context/isOnline"

type ProvidersProps = {
  children: React.ReactNode
}
export const Providers = ({ children }: ProvidersProps) => {
  return (
    <AuthProvider>
      <IsUserOnlineOnTwitchProvider>{children}</IsUserOnlineOnTwitchProvider>
    </AuthProvider>
  )
}
