import { useState } from "react"
import { PostObsVerify } from "@/services/request/ObsVerify"

import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { useUser } from "@/hook/useUser"
import { Copy, Eye, EyeOff, Fan } from "lucide-react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { toast } from "sonner"
import { useQuery } from "@tanstack/react-query"
import { Spinner } from "@/components/Spinner"

export function CopyObsLink() {
  const { data: user } = useUser()
  const { t } = useTranslation()
  const [seeLink, setSeeLink] = useState(false)
  const OBS_LINK = window.location.host + "/obs/" + user?.name

  const { refetch: handleVerify, isFetching } = useQuery({
    queryKey: ["obs-verify"],
    queryFn: () => PostObsVerify({ account_id: user!.id }),
    enabled: false,
  })

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div className="flex gap-2">
            <Fan className="text-slate-600" />
            {t("Profile.OBS.title")}
          </div></CardTitle>
        <CardDescription>{t("Profile.OBS.description")}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex w-full flex-wrap items-center justify-start gap-2">
          <Input
            readOnly
            className="max-w-[350px] grow"
            value={
              seeLink
                ? OBS_LINK
                : Array.from(OBS_LINK)
                    .map(() => "*")
                    .join("")
            }
          />

          <Button
            size="icon"
            variant="secondary"
            onClick={() => setSeeLink(!seeLink)}
          >
            {seeLink ? <EyeOff /> : <Eye />}
          </Button>

          <CopyToClipboard
            text={OBS_LINK}
            onCopy={() => toast.success(t("Profile.LinkCopied"))}
          >
            <Button variant="secondary" size="icon" className="shrink-0">
              <Copy />
            </Button>
          </CopyToClipboard>

          <Button disabled={isFetching} onClick={() => handleVerify()}>
            {isFetching && <Spinner />}
            {t("Profile.OBS.test")}
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

