
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { createProductSchema, TCreateProduct } from "@/types"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { GetCategories } from "@/services/request/Category"
import { DuoPage } from "../../../views/Duo/DuoPage"
import { CreateDuoForm } from "@/views/Duo/CreateDuoForm"

export default function CreateDuo() {
  const { t } = useTranslation()
  const form = useForm<TCreateProduct>({
    defaultValues: {
      amount: 4,
    },
    resolver: zodResolver(createProductSchema(t)),
  })

  const { data: categories } = useQuery({
    queryKey: ["getCategories"],
    queryFn: GetCategories,
  })

  const chooseCategory = categories?.find(
    (category) => category.id === form.watch("product_description_id"),
  )

  return (
    <DuoPage
      title={t("Duo.create")}
      product={{
        ...form.getValues(),
        avatar: chooseCategory?.avatar,
        name: chooseCategory?.description,
      }}
      sideSlot={null}
    >
      <CreateDuoForm form={form} categories={categories} />
    </DuoPage>
  )
}